.cooler-wrapper {
  height: 500px;
  display: inline-block;
  background: white;
  white-space:normal;
  vertical-align:top;
}

.cooler {
  height: 100%;
  width: 100%;
  border: 2px solid var(--primary);
  display: flex;
  flex-direction: column;
}