.header {
  display: flex;
  justify-content: space-between;
  height: 8%;
}

.planogram-container {
  height: 90vh;
  margin: 0.5rem;
  border: 2px solid black;
}

.planogram {
  height: 92%;
  background-color: darkgray;
  display: flex;
  width: 100%;
}

.product-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
  overflow: scroll;
}

.cooler-container {
  overflow:auto;
  white-space:nowrap;
  flex: 4 1 0;
}